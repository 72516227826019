<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3 col-6">
                                <v-select v-model="millModel" :items="millList" default="" item-value="mill_id" item-text="mill_name" label="SHIP FROM(MILL)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-autocomplete v-model="DelivNoModel" :items="DelivNoList" item-value="deliv_id" item-text="deliv_to" :search-input.sync="suratJalanSearch" cache-items flat hide-no-data
                                hide-details allow-overflow dense label="DELIV NO." clearable @change="(event) => onChangeDelivNo(event)"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="loadModel" :items="loadList" item-value="value" item-text="text" label="LOADED" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="printModel" :items="printList" item-value="value" item-text="text" label="PRINTED" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="rolledModel" :items="rolledList" item-value="value" item-text="text" label="ROLLED(Gelondongan)" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Ship Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="Ship End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-6 col-12">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'GBRK Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Delivery Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millList: [],
            DelivNoModel: '',
            DelivNoList: [],
            loadModel: '',
            loadList: [
                {
                    value:'Y',
                    text:'YES'
                },
                {
                    value:'N',
                    text:'NO'
                }
            ],
            printModel: '',
            printList: [
                {
                    value:'Y',
                    text:'YES'
                },
                {
                    value:'N',
                    text:'NO'
                }
            ],
            rolledModel: '',
            rolledList: [
                {
                    value:'Y',
                    text:'YES'
                },
                {
                    value:'N',
                    text:'NO'
                }
            ],
            dateStartModal: false,
            dateStartModel: '',
            dateEndModal: false,
            dateEndModel: '',
            itemLists: [],
            headers: [
                { text: 'MILL', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DELIV NO.', value: 'noSJ', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIP. DATE', value: 'tglKirim', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'LOADED', value: 'muat', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRINTED', value: 'cetak', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ROLLED ID', value: 'glondong_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIP. ADDRESS', value: 'Alamat_kirim', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            searchItem: '',
            loadingDatatable: false,
            suratJalanSearch: null
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)
            $(".cardResult").hide()

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/DelivReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.millList = res.data.mill
                

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'warning',
                    })
                return false
            }

            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/DelivReport/getData`, { 

                mill: this.millModel ? this.millModel : '',
                deliv_no: this.DelivNoModel ? this.DelivNoModel : '',
                isLoaded: this.loadModel ? this.loadModel : '',
                isPrinted: this.printModel ? this.printModel : '',
                isRolled: this.rolledModel ? this.rolledModel : '',
                startDate: this.dateStartModel ? this.dateStartModel : '',
                endDate: this.dateEndModel ? this.dateEndModel : '',

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                $(".cardResult").show()
                this.loadingDatatable = false
                this.itemLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },

        async onChangeDelivNo(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/DelivReport/getDelivNo`, { 
                    id : id,
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.DelivNoList = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

    },
    watch: {
        suratJalanSearch (val) {
            val && val !== this.DelivNoModel && this.onChangeDelivNo(val)
        },
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>